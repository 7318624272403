<!-- <template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
</style> -->

<template>
  <v-card>
    <router-view></router-view>
  <!-- <dashboard></dashboard> -->
  </v-card>
  </template>
  
  <script>
  import Dashboard from './Dashboard.vue';
  
  export default {
    components: { Dashboard}}
  </script>
  
  <style>
  </style>